<template>
  <div>
    <dynamic-template :name="mainTemplate"></dynamic-template>
    <dynamic-template name="FooterBar"></dynamic-template>
  </div>
</template>

<script>
import DynamicTemplate from '../components/DynamicTemplate';

export default {
  name: 'layout9',
  props: ['data'],
  components: { DynamicTemplate },
  computed: {
    mainTemplate() {
      return this.$store.state.mainTemplate;
    }
  }
};
</script>

<style lang="scss"></style>
